import Gen from "@helper/Gen"

let detectHost = (location.origin || '').indexOf('capediscovery') > -1

var fo_router = [
    {
        name: "index",
        path: "home",
        component: () => import("./frontend/Home.vue"),
    },
    {
        name: "CDProses",
        path: "cd-proses",
        component: () => import("./frontend/CDProses.vue"),
    },
    {
        name: "CDCollection",
        path: detectHost  ? '/' :"cd-collection",
        component: () => import("./frontend/CDCollection.vue"),
    },
    {
        name: "CDHowToEnjoy",
        path: "cd-how-to-enjoy",
        component: () => import("./frontend/CDHowToEnjoy.vue"),
    },
    {
        name: "BLProses",
        path: "bl-proses",
        component: () => import("./frontend/BLProses.vue"),
    },
    {
        name: "BLCollection",
        path: detectHost ? 'bl-collection' : "/",
        component: () => import("./frontend/BLCollection.vue"),
    },
    {
        name: "BLHowToEnjoy",
        path: "bl-how-to-enjoy",
        component: () => import("./frontend/BLHowToEnjoy.vue"),
    },
    {
        name: "Story",
        path: "story",
        component: () => import("./frontend/Story.vue"),
    },
    {
        name: "Blog",
        path: "blog",
        component: () => import("./frontend/Blog.vue"),
    },
    {
        name: "DetailBlog",
        path: "blog/:slug",
        component: () => import("./frontend/DetailBlog.vue"),
    },
    {
        name: "Cities",
        path: "cities",
        component: () => import("./frontend/Cities.vue"),
    },
    {
        name: "ContactUs",
        path: "contact",
        component: () => import("./frontend/ContactUs.vue"),
    },
    {
        name: "Subscribe",
        path: "subscribe",
        component: () => import("./frontend/Subscribe.vue"),
    },
    {
        name: "TermsConditions",
        path: "terms-conditions",
        component: () => import("./frontend/TermsConditions.vue"),
    },
    {
        name: "PrivacyPolicy",
        path: "privacy-policy",
        component: () => import("./frontend/PrivacyPolicy.vue"),
    },
    {
        name: "PrivacyCookie",
        path: "cookie-policy",
        component: () => import("./frontend/PrivacyCookie.vue"),
    },
    {
        name: "Login",
        path: "login",
        component: () => import("./frontend/Login.vue"),
    },
    {
        name: "ForgotPassword",
        path: "forgot-password",
        component: () => import("./frontend/ForgotPassword.vue"),
    },
    {
        name: "ChangePassword",
        path: "change-password/:code",
        component: () => import("./frontend/ChangePassword.vue"),
    },
    {
        name: "Register",
        path: "register",
        component: () => import("./frontend/Register.vue"),
    },
    {
        name: "Page404",
        path: "*",
        component: () => import("./frontend/Page404.vue"),
    },
]
fo_router.map((v)=>{
    v.beforeEnter = (to, from, next)=>{
        if(typeof $ != 'undefined') $("html,body").scrollTop(0)
        next()
    }
})
var data = [
    {
        name: "logout",
        path: "logout",
        beforeEnter(from, to, next){
            Gen.delCookie("fotk")
            Gen.delStorage("fotk")
            if(typeof App != "undefined") App.user = null
            next({name:'index'})
        },
    },
    {
        path:"/",
        component: () => import('@/layout/FoLayout.vue'),
        children: fo_router
    },
]
export default data